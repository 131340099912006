



















import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import 'mathlive';

(window.mathVirtualKeyboard as any).targetOrigin = '*';

export default defineComponent({
  name: 'UCIMathSandboxQ1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      questions: [
        {
          text:
            'Use Integration by Parts (IBP) to compute the following antiderivative.' +
            '\n a) $\\int xe^{-2x}dx$',
          inputName: 'studentAnswer',
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(
      () =>
        document.getElementById('studentFormulaAnswer')?.addEventListener('input', (ev) => {
          this.inputs.studentAnswer = (ev.target as any)?.value;
        })
    );
  },
});
